<template>
  <v-container>
    <PageTitle text="Rates and Insurance" />
    <PageText :text="p1" />
    <v-row>
      <v-col cols="1" md="3"></v-col>
      <v-col cols="10" md="6">
        <p>
          Questions to consider asking your insurance carrier:
          <ul>
            <li>Does my current health insurance plan include mental health benefits?</li>
            <li>Do I have a deductible and has it been met?</li>
            <li>Does my plan limit how many sessions are covered in a calendar year?</li>
            <li>What is the covered amount per session?</li>
            <li>
              Is approval required from my primary care physician in order for psychotherapy to be
              covered?
            </li>
          </ul>
        </p>
      </v-col>
      <v-col cols="1" md="3"></v-col>
    </v-row>

    <h2 class="pb-5 text-center">Insurance</h2>
    <v-row>
      <v-col cols="1" md="3"></v-col>
      <v-col cols="10" md="6">
        <p>
          Keep in mind that insurance acceptance is subject to change. At this time, I accept most
          major insurance plans, including:
          <ul>
            <li>Medicaid</li>
            <li>Medicare</li>
            <li>Blue Cross Blue Shield of Idaho</li>
            <li>Pacific Source</li>
            <li>Cigna</li>
            <li>Aetna</li>
          </ul>
        </p>
      </v-col>
      <v-col cols="1" md="3"></v-col>
    </v-row>
    <PageText :text="p2" />
    <v-row>
      <v-col cols="1" md="3"></v-col>
      <v-col cols="10" md="6">
        <h2 class="pb-5 text-center">Rates</h2>
        <ul>
          <li>Initial Intake Session: 75 Minutes -- $175</li>
          <li>45-50 Minute Session -- $130</li>
          <li>60-75 Minute Session -- $150</li>
          <li>60 Minutes of Clinical Supervision -- $80</li>
        </ul>
      </v-col>
      <v-col cols="1" md="3"></v-col>
    </v-row>
    <PageText :text="p3" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
  components: { PageText, PageTitle },
  data: () => ({
    alert: `
      I have applied to accept the insurances listed below. The process can take months. Please
      feel free to contact me via email to be placed on a callback list for when the insurance
      process is complete.
    `,
    p1: `
      Treatment services may be covered in full or in part by your health insurance. Please
      contact your insurance provider to verify coverage for psychotherapy services. If it is
      determined that psychotherapy is not covered, you will be responsible for the full session
      fee.
    `,
    p2: `
      I accept cash, check, and major credit cards as forms of payment. Payment or copay is
      collected prior to the session. I take one pro bono client at a time; if you believe you
      would benefit from my therapeutic services, but are unable to afford treatment, contact me
      for potential availability.
    `,
    p3: `
      If you are unable to attend your scheduled session, please cancel 24 hours in advance. If
      multiple appointments are cancelled without the appropriate notice, you may be charged the
      full rate of the session.
    `,
  }),
};
</script>

<style scoped>
</style>
